/** @format */

import { FunctionComponent as FC, useEffect, useMemo, useRef, useState } from 'react';
import EnIcon from '../../assets/icons/en_icon.svg';
import EsIcon from '../../assets/icons/es_icon.svg';
import FrIcon from '../../assets/icons/fr_icon.svg';
import ItIcon from '../../assets/icons/it_icon.svg';
import PtIcon from '../../assets/icons/pt_icon.svg';
import { Arrow, Dropdown, IconBtn, Locale, Wrapper } from './ChangeLanguage.style';

import { useWindowHeight } from '@/hooks/useWindowHeight';
import { useRouter } from 'next/router';
import { useLanguageSwitcherContext } from '../../context/LanguageSwitcherContext';
import { GTMChangeLanguageClick } from '../../lib/google-tag-manager';
import { InboundLink } from '../ui/Link';
import { i18nContext } from '@/context/i18nContext';

export const ChangeLanguage: FC<Props> = ({ variant, position, urlExtension = true }) => {
  const { locale, locales } = useRouter();
  const localeToShow = useMemo(() => (locale === 'default' ? 'en' : locale), [locale]);
  const { slugs } = useLanguageSwitcherContext();
  const windowHeight = useWindowHeight();

  const displayLocales = locales?.filter(l => l !== 'default') ?? [];
  const [isLangsOpen, setIsLangsOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const [align, setAlign] = useState('top');
  const dropdownRef = useRef<HTMLUListElement | null>(null);
  const menuButtonRef = useRef<HTMLButtonElement | null>(null);

  const localeIcon = useMemo(() => {
    switch (localeToShow) {
      case 'en':
        return EnIcon;
      case 'fr':
        return FrIcon;
      case 'es':
        return EsIcon;
      case 'it':
        return ItIcon;
      case 'pt':
        return PtIcon;
      default:
        return null;
    }
  }, [localeToShow]);

  const onClickOutside = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setIsLangsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', onClickOutside, true);
    return () => {
      document.removeEventListener('click', onClickOutside, true);
    };
  }, []);

  const toggleDropdown = () => setIsLangsOpen(prev => !prev);

  useEffect(() => {
    if (dropdownRef.current) {
      if (!windowHeight) return;
      const boundingDropdown = dropdownRef?.current?.getBoundingClientRect();
      const boundingMenuButtonRef = menuButtonRef?.current?.getBoundingClientRect();
      if (!boundingMenuButtonRef) return;
      if (windowHeight - boundingMenuButtonRef.y < boundingDropdown.height) setAlign('top');
      else setAlign('bottom');
    }
  }, [windowHeight]);

  return (
    <i18nContext.Consumer>
      {t => (
        <Wrapper ref={ref}>
          <IconBtn
            type="button"
            aria-haspopup="menu"
            aria-expanded={isLangsOpen}
            title={t['aria-label']['changeLanguage']}
            onClick={toggleDropdown}
            $active={isLangsOpen}
            ref={menuButtonRef}
            variant={variant}
          >
            <Locale icon={localeIcon} />
            {localeToShow}
            <Arrow />
          </IconBtn>
          {Object.keys(slugs).length > 0 && (
            <Dropdown ref={dropdownRef} tabIndex={-1} $show={isLangsOpen} $align={align}>
              {displayLocales.map(l => (
                <li key={l}>
                  <InboundLink
                    href={slugs[l]}
                    locale={l}
                    urlExtension={urlExtension}
                    data-page-active={locale === l}
                    onClick={() => {
                      setIsLangsOpen(false);
                      GTMChangeLanguageClick(l, position);
                    }}
                  >
                    {l}
                  </InboundLink>
                </li>
              ))}
            </Dropdown>
          )}
        </Wrapper>
      )}
    </i18nContext.Consumer>
  );
};

type Props = {
  variant: 'dark' | 'light';
  position: 'menu' | 'header' | 'footer';
  urlExtension?: boolean;
};
