/** @format */

import Logo from '@/assets/logo-yellow.svg';
import { useGtmLabel } from '@/hooks/useGtmLabel';
import { GTMHeaderClick, GTMLinkClick } from '@/lib/google-tag-manager';
import { MenuItem } from '@/types/page';
import { Entry } from 'contentful';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { FunctionComponent as FC, Fragment, useMemo } from 'react';
import { Container } from '../ui/Layout';
import { InboundLink } from '../ui/Link';
import { NavigationLink } from '../ui/Typography';
import { ChangeLanguage } from './ChangeLanguage';
import { MobileNav } from './MobileNav';
import { NavDropdown } from './NavDropdown';
import { NavbarContainer, NavbarList } from './Navbar.style';

const Navbar: FC<Props> = ({ navItems, landing = false }) => {
  const { locale, route } = useRouter();
  const menuItems = useMemo(() => navItems.map(item => item.fields), [navItems]);
  const { getGTMLabel } = useGtmLabel();

  const renderNavItems = (items: MenuItem[]) => {
    return items.map(item => {
      const gtmLabel = getGTMLabel(item);

      return (
        <Fragment key={item.label}>
          {item.url && (
            <li>
              <NavigationLink
                item={item.url}
                locale={locale}
                data-active={route === `/${item.url}`}
                onClick={() => GTMLinkClick(gtmLabel, 'menu_section')}
              >
                {item.label}
              </NavigationLink>
            </li>
          )}
          {item.subItems && <NavDropdown title={item.label} links={item.subItems} id={item.id} />}
        </Fragment>
      );
    });
  };

  return (
    <NavbarContainer $locale={locale} $landing={landing}>
      <Container>
        <InboundLink href="/" urlExtension={!landing} locale={locale} onClick={() => GTMHeaderClick('logo')}>
          <Image src={Logo} alt="logo" width={179} height={52} />
        </InboundLink>
        <NavbarList $locale={locale} $highlightLast={!landing}>
          {navItems && renderNavItems(menuItems)}
          <ChangeLanguage position="header" variant="light" urlExtension={!landing} />
        </NavbarList>
        <MobileNav items={menuItems} landing={landing} />
      </Container>
    </NavbarContainer>
  );
};

export default Navbar;

type Props = {
  navItems: Entry<MenuItem>[];
  landing?: boolean;
};
